import { Chess } from "@lubert/chess.ts";
import type { Move, Square } from "@lubert/chess.ts/dist/types";
import * as Sentry from "@sentry/browser";
import { addDays } from "date-fns";
import {
	cloneDeep,
	countBy,
	drop,
	filter,
	find,
	first,
	flatMap,
	forEach,
	includes,
	isEmpty,
	isNil,
	last,
	map,
	noop,
	reverse,
	shuffle,
	slice,
	some,
	sortBy,
	take,
	takeWhile,
	values,
} from "lodash-es";
import { RepertoireBuilder } from "~/components/RepertoireBuilder";
import { RepertoireReview } from "~/components/RepertoireReview";
import { ReviewComplete } from "~/components/ReviewComplete";
import { ReviewsBlockedByFreeTierWarning } from "~/components/ReviewsBlockedByFreeTierWarning";
import type { SidebarAction } from "~/components/SidebarActions";
import { animateSidebar } from "~/components/SidebarContainer";
import { SidebarTemplate } from "~/components/SidebarTemplate";
import type { EcoCode } from "~/types/EcoCode";
import type { LichessMistake } from "~/types/GameMistake";
import { Line } from "~/types/Line";
import { Repertoire } from "~/types/Repertoire";
import type { RepertoireMove } from "~/types/RepertoireMove";
import type { SanPlus } from "~/types/SanPlus";
import type { Side } from "~/types/Side";
import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";
import type { Uuid } from "~/types/Uuid";
import { Kep } from "~/types/kep";
import client from "~/utils/client";
import { trackEvent } from "~/utils/trackEvent";
import { APP_STATE, type AppState, REPERTOIRE_STATE, UI, USER_STATE } from "./app_state";
import { quick } from "./app_state";
import { START_EPD } from "./chess";
import { type ChessboardInterface, createChessboardInterface } from "./chessboard_interface";
import { type FrontendSettingOption, ReviewStyle } from "./frontend_settings";
import { getAllPossibleMoves } from "./move_generation";
import { MultiCallback } from "./multi_callback";
import { getMaxMovesPerSideOnFreeTier } from "./payment";
import { getMaxPlansForQuizzing, parsePlansToQuizMoves } from "./plans";
import { Quiz, type QuizGroup, countQueue } from "./queues";
import type { RepertoireState } from "./repertoire_state";
import { COMMON_MOVES_CUTOFF, EARLY_MOVES_CUTOFF } from "./review";
import { logProxy } from "./state";
import { dailyTaskFinished } from "./streaks";

export interface ReviewPositionResults {
	side: Side;
	correct: boolean;
	epd: string;
	sanPlus: string;
}

type Epd = string;
type QuizGroupOutcome = "correct" | "incorrect";

export type ReviewState = ReturnType<typeof getInitialReviewState>;

type Stack = [ReviewState, RepertoireState, AppState];
type ReviewStats = {
	due: number;
	correct: number;
	incorrect: number;
};

type ReviewFilter = "difficult-due" | "all" | "recommended" | "due" | "early" | "difficult";

interface ReviewOptions {
	repertoireId?: Uuid | Side;
	startPosition?: string;
	startLine?: string[];
	cram?: boolean;
	filter?: ReviewFilter;
	customQueue?: QuizGroup[];
	lichessMistakes?: LichessMistake[];
	includePlans?: boolean;
	cameFrom?: "review_specific_line";
}

const FRESH_REVIEW_STATS = {
	due: 0,
	correct: 0,
	incorrect: 0,
} as ReviewStats;

export enum QuizGroupStage {
	Start = 0,
	Hints = 1,
	Arrows = 2,
	Full = 3,
}

export const getInitialReviewState = () => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string) => {
		return quick((s) => {
			return fn([s.repertoireState.reviewState, s.repertoireState, s]);
		});
	};
	const get = <T,>(fn: (stack: Stack) => T) => {
		return fn([APP_STATE().repertoireState.reviewState, APP_STATE().repertoireState, APP_STATE()]);
	};
	const initialState = {
		originalQueue: null as QuizGroup[] | null,
		viewingLastQuizGroup: false,
		moveLog: [] as string[],
		failedDifficultMove: false,
		failedReviewPositionMoves: {} as Record<string, RepertoireMove>,
		completedReviewPositionMoves: {} as Record<string, RepertoireMove>,
		allReviewPositionMoves: {} as Record<
			Epd,
			Record<
				SanPlus,
				{
					sanPlus: string;
					epd: string;
					failed: boolean;
					side: Side;
					reviewed: boolean;
				}
			>
		>,
		skipFirstPractice: () => {
			set(([s, _rs]) => {
				forEach([...s.activeQueue, ...(s.currentQuizGroup ? [s.currentQuizGroup] : [])], (q) => {
					forEach(Quiz.getMoves(q), (m) => {
						if (m.learning) {
							m.learning = false;
						}
					});
				});
			});
		},
		quizGroupStage: QuizGroupStage.Start,
		// @ts-ignore
		chessboard: undefined as ChessboardInterface,
		reviewStats: cloneDeep(FRESH_REVIEW_STATS),
		reviewSide: "white" as Side,
		activeOptions: null as ReviewOptions | null,
		activeQueue: [] as QuizGroup[],
		planIndex: 0,
		onSessionEnd: new MultiCallback(),
		currentQuizGroup: null as QuizGroup | null,
		incorrectGuessesThisQuizGroup: 0,
		previousQuizGroup: null as QuizGroup | null,
		markMovesReviewed: (results: ReviewPositionResults[]) => {
			set(([_s, rs]) => {
				results.forEach((r, _i) => {
					forEach(rs.getRepertoires({ side: r.side, includeSupers: true }), (repertoire) => {
						repertoire.positionResponses[r.epd]?.forEach((m: RepertoireMove) => {
							if (m.sanPlus === r.sanPlus && m.srs) {
								if (r.correct) {
									m.srs.dueAt = addDays(new Date(), 1).toISOString();
								}
							}
						});
					});
				});
			});
			client.post("/api/v1/openings/moves_reviewed", { results }).then(({ data: updatedSrss }) => {
				set(([_s, rs]) => {
					results.forEach((move, i) => {
						forEach(rs.getRepertoires({ side: move.side }), (repertoire) => {
							repertoire.positionResponses[move.epd]?.forEach((m: RepertoireMove) => {
								if (m.sanPlus === move.sanPlus && m.srs) {
									m.srs = updatedSrss[i];
								}
							});
						});
					});
					rs.onMoveReviewed();
				});
			});
		},
		resumeReview: () => {
			set(([_s, _rs, _gs]) => {
				UI().mode = "review";
			});
		},
		lastEcoCode: undefined as EcoCode | undefined,
		onPositionUpdate: () => {
			set(([s, rs]) => {
				s.moveLog = s.chessboard.get((s) => s.moveLog);
				if (rs.ecoCodeLookup) {
					s.lastEcoCode = rs.getLastEcoCode(s.chessboard.get((s) => s.positionHistory));
				}
			});
		},
		startReview: (options: ReviewOptions) =>
			set(([s, rs, _gs]) => {
				s.reviewStats = cloneDeep(FRESH_REVIEW_STATS);
				s.currentQuizGroup = null;
				animateSidebar("right");
				const repertoire = options.repertoireId
					? rs.getStandardAndSuperRepertoires()?.[options.repertoireId]
					: null;
				rs.browsingState.activeSide = repertoire?.side ?? undefined;
				s.activeOptions = options ?? null;
				let numBlockedByFreeTier = 0;
				let numNotBlockedByFreeTier = 0;
				if (options.lichessMistakes) {
					s.activeQueue = s.buildQueueFromMistakes(options.lichessMistakes);
				} else if (options.customQueue) {
					s.activeQueue = cloneDeep(options.customQueue);
				} else {
					s.updateQueue(options);
					s.activeQueue = s.activeQueue.filter((q) => {
						if (q.blockedByFreeTier) {
							numBlockedByFreeTier++;
							return false;
						}
						numNotBlockedByFreeTier++;
						return true;
					});
				}
				let lastGroup: QuizGroup | null = null;
				s.activeQueue = flatMap(s.activeQueue, (q) => {
					const groups = Quiz.appendExpandedQuizGroup(lastGroup, q);
					lastGroup = q;
					return groups;
				});
				s.allReviewPositionMoves = {};
				s.activeQueue.forEach((m) => {
					Quiz.getMoves(m)?.forEach((m) => {
						if (!s.allReviewPositionMoves[m.epd]) {
							s.allReviewPositionMoves[m.epd] = {};
						}
						s.allReviewPositionMoves[m.epd][m.sanPlus] = {
							epd: m.epd,
							sanPlus: m.sanPlus,
							side: m.side,
							failed: false,
							reviewed: false,
						};
					});
				});
				s.originalQueue = cloneDeep(s.activeQueue);
				s.setupNextQuizGroup();
				rs.fetchNeededAnnotations();
				if (numBlockedByFreeTier > 0) {
					UI().pushView(ReviewsBlockedByFreeTierWarning, {
						props: {
							total: numNotBlockedByFreeTier + numBlockedByFreeTier,
							blocked: numBlockedByFreeTier,
						},
					});
				} else {
					UI().pushView(RepertoireReview);
				}
			}),
		setupPlans: () =>
			set(([s, _rs]) => {
				const remaningPlans = Quiz.getRemainingPlans(s.currentQuizGroup!, s.planIndex);
				const plan = first(remaningPlans);
				s.chessboard.set((c) => {
					c.hideLastMoveHighlight = true;
				});
				if (!plan) {
					s.quizGroupStage = QuizGroupStage.Full;
					s.chessboard.set((c) => {
						c.showPlans = true;
						c.plans = (Quiz.getCompletedPlans(s.currentQuizGroup!, s.planIndex) ?? []).map(
							(p) => p.metaPlan,
						);
					});
					return;
				}
				let squares = getAllPossibleMoves({
					square: plan.fromSquare,
					side: s.currentQuizGroup!.side,
					piece: plan.piece,
				});
				if (plan.options) {
					squares = plan.options;
				}
				s.chessboard.setTapOptions(squares);
				s.chessboard.setMode("tap");
				s.chessboard.highlightSquare(plan?.fromSquare ?? null);
			}),
		markGameMistakeReviewed: (lichessMistake: LichessMistake) => {
			set(([_s, rs]) => {
				rs.lichessMistakes?.shift();
				client.post("/api/v1/game_mistake_reviewed", {
					gameId: lichessMistake.gameId,
					epd: lichessMistake.epd,
					createdAt: lichessMistake.timestamp,
				});
			});
		},
		backToLastQuizGroup: () =>
			set(([s]) => {
				s.viewingLastQuizGroup = true;
				s.activeQueue.unshift(s.currentQuizGroup!);
				s.currentQuizGroup = s.previousQuizGroup;
				s.previousQuizGroup = null;
				s.setupCurrentQuizGroup();
				if ((Quiz.getMoves(s.currentQuizGroup!) || []).length > 1) {
				} else {
					s.playCorrectMove({ markAs: null });
				}
				s.quizGroupStage = QuizGroupStage.Full;
			}),
		setupNextQuizGroup: () =>
			set(([s, rs, _gs]) => {
				s.previousQuizGroup = null;
				s.viewingLastQuizGroup = false;
				s.chessboard.setFrozen(false);
				s.failedDifficultMove = false;
				s.planIndex = 0;
				addFailedGroup: if (s.currentQuizGroup && !s.viewingLastQuizGroup) {
					if (s.currentQuizGroup.synthetic) {
						break addFailedGroup;
					}
					const failedMoves = values(s.failedReviewPositionMoves);
					const moves = Quiz.getMoves(s.currentQuizGroup);
					const learningAny = some(moves, (m) => m.learning);
					if ((!isEmpty(failedMoves) && !s.activeOptions?.lichessMistakes) || learningAny) {
						const newGroup = {
							moves: (moves ?? []).map((m) => ({
								...m,
								learning: m.learning ? false : undefined,
							})),
							line: s.currentQuizGroup.line,
							side: s.currentQuizGroup.side,
							epd: s.currentQuizGroup.epd,
						};
						if (learningAny) {
							// find the next index where the group line lenght is less than the previous group line
							let firstShorterLineIndex = s.activeQueue.findIndex((group, i) => {
								const prevGroup = s.activeQueue[i - 1];
								const secondPassLearningGroup = some(
									Quiz.getMoves(group),
									(m) => m.learning === false,
								);
								if (secondPassLearningGroup || group.synthetic) {
									return false;
								}
								if (prevGroup && group.line.length < prevGroup.line.length) {
									return true;
								}
								return false;
							});
							if (firstShorterLineIndex === -1) {
								s.activeQueue.push(newGroup);
							} else {
								for (let i = firstShorterLineIndex; i < s.activeQueue.length; i++) {
									const isSecondLearningPass = some(
										Quiz.getMoves(s.activeQueue[i]),
										(m) => m.learning === false,
									);
									if (!isSecondLearningPass) {
										firstShorterLineIndex = i;
										break;
									}
								}
								s.activeQueue.splice(firstShorterLineIndex, 0, newGroup);
								let remaining = slice(s.activeQueue, firstShorterLineIndex + 1);
								const numSynthetic = takeWhile(remaining, (g) => g.synthetic).length;
								if (numSynthetic > 0) {
									const next = remaining[numSynthetic + 1];
									remaining = drop(remaining, numSynthetic);
									if (next) {
										const newGroups = Quiz.appendExpandedQuizGroup(newGroup, next);
										remaining = [...newGroups, ...remaining];
										s.activeQueue = remaining;
									}
								}
							}
						} else {
							s.activeQueue.push(
								...Quiz.appendExpandedQuizGroup(last(s.activeQueue) ?? null, newGroup),
							);
						}
					}
					const lichessMistake = s.currentQuizGroup.lichessMistake;
					if (s.currentQuizGroup && !s.previousQuizGroup) {
						dailyTaskFinished();
					}
					if (lichessMistake) {
						s.markGameMistakeReviewed(lichessMistake);
						rs.onGameMistakeReviewed();
					} else if (moves) {
						s.markMovesReviewed(
							moves.map((m) => {
								const failed = s.failedReviewPositionMoves[m.sanPlus];
								return {
									side: m.side,
									epd: m.epd,
									sanPlus: m.sanPlus,
									correct: !failed,
								};
							}),
						);
					}
				}
				if (s.currentQuizGroup && !Quiz.isPlansQuiz(s.currentQuizGroup)) {
					s.previousQuizGroup = cloneDeep(s.currentQuizGroup);
				}
				s.currentQuizGroup = s.activeQueue.shift() ?? null;
				s.setupCurrentQuizGroup();
				if (!s.currentQuizGroup) {
					rs.updateRepertoireStructures();
					UI().cutView();
					UI().pushView(ReviewComplete);
					trackEvent("review.review_complete");
					return;
				}
			}),

		setupCurrentQuizGroup: () =>
			set(([s, _rs]) => {
				s.quizGroupStage = QuizGroupStage.Start;
				s.incorrectGuessesThisQuizGroup = 0;
				s.chessboard.setTapOptions([]);
				s.failedReviewPositionMoves = {};
				s.completedReviewPositionMoves = {};
				s.chessboard.setMode("normal");
				s.chessboard.highlightSquare(null);
				if (!s.currentQuizGroup) {
					return;
				}
				s.reviewStats.due = countQueue(s.activeQueue);
				s.reviewSide = s.currentQuizGroup.side;
				s.chessboard.setPerspective(s.currentQuizGroup.side);
				s.chessboard.set((c) => {
					c.plans = [];
				});

				const plans = Quiz.getPlans(s.currentQuizGroup);
				if (plans) {
					s.setupPlans();
				} else {
					s.chessboard.set((c) => {
						c.showPlans = false;
						c.hideLastMoveHighlight = false;
					});
					s.chessboard.playLine(s.currentQuizGroup.line, {
						animated: !s.viewingLastQuizGroup,
					});
				}
			}),

		playCorrectMove: ({ markAs }: { markAs: QuizGroupOutcome | null }) => {
			set(([s]) => {
				const move = s.getNextReviewPositionMove();
				if (!move) {
					return;
				}
				const moveObj = s.chessboard.get((s) => s.position).validateMoves([move.sanPlus])?.[0];
				if (!moveObj) {
					// todo : this should queue up instead of silently doing nothing
					console.error("Invalid move", logProxy(move));
					return;
				}
				s.markRemaining({ markAs, completed: true });
				s.completedReviewPositionMoves[move.sanPlus] = move;
				s.chessboard.setFrozen(true);
				s.chessboard.makeMove(moveObj, { animate: true, sound: "move" });
			});
		},
		markRemaining: ({
			markAs,
			completed,
		}: { markAs: QuizGroupOutcome | null; completed: boolean }) =>
			set(([s]) => {
				s.getRemainingReviewPositionMoves().forEach((move) => {
					if (markAs === "correct") {
						s.reviewStats.correct++;
					} else if (isEmpty(s.failedReviewPositionMoves) && markAs === "incorrect") {
						s.reviewStats.incorrect++;
					}
					if (markAs === "incorrect") {
						s.failedReviewPositionMoves[move.sanPlus] = move;
					}
					if (markAs) {
						s.allReviewPositionMoves[move.epd][move.sanPlus].failed = markAs === "incorrect";
						s.allReviewPositionMoves[move.epd][move.sanPlus].reviewed = true;
					}
					if (completed) {
						s.completedReviewPositionMoves[move.sanPlus] = move;
					}
				});
			}),
		giveUp: () =>
			set(([s, _rs]) => {
				if (s.currentQuizGroup && Quiz.isPlansQuiz(s.currentQuizGroup)) {
					s.planIndex++;
					s.setupPlans();
					return;
				}
				if (s.hasAnnotationsForCurrentQuizGroup() && s.quizGroupStage !== QuizGroupStage.Hints) {
					s.quizGroupStage = QuizGroupStage.Hints;
					s.markRemaining({ markAs: "incorrect", completed: false });
				} else if (s.isCurentMoveVeryDifficult()) {
					s.onFailDifficultMove();
				} else {
					s.quizGroupStage = QuizGroupStage.Arrows;
					s.markRemaining({ markAs: "incorrect", completed: false });
				}
			}, "giveUp"),
		hasAnnotationsForCurrentQuizGroup: () => {
			return get(([s, rs]) => {
				if (!s.currentQuizGroup) {
					return false;
				}
				const moves = Quiz.getMoves(s.currentQuizGroup);
				const annotations = filter(
					moves?.map((m) => rs.getAnnotation(Kep.toKey({ epd: m.epd, san: m.sanPlus }))?.text),
					(a) => a,
				) as string[];
				const hasAnnotations = !isEmpty(annotations);
				return hasAnnotations;
			});
		},
		stopReviewing: () =>
			set(([s, rs]) => {
				rs.updateRepertoireStructures();
				s.chessboard.setTapOptions([]);
				s.onSessionEnd.callAndClear();
			}),
		buildQueue: (options: ReviewOptions) =>
			get(([_s, rs, gs]) => {
				const onboarding = rs.onboarding.isOnboarding;
				if (gs.userState.flagEnabled("quiz_plans")) {
					options.includePlans = true;
				}
				if (isNil(rs.repertoires)) {
					return [];
				}
				let freeTierMoveIds: Record<Side, Set<string>> = { white: new Set(), black: new Set() };
				let freeTierLimit = getMaxMovesPerSideOnFreeTier()[0];
				if (!USER_STATE().isSubscribed()) {
					forEach(rs.superRepertoires, (repertoire) => {
						let moves = reverse(
							sortBy(
								filter(Repertoire.getAllEnabledRepertoireMoves(repertoire), (move) => move.mine),
								(move) => {
									return move.mine ? move.incidence ?? 0 : 0;
								},
							),
						);
						moves = take(moves, freeTierLimit);
						forEach(moves, (move) => {
							freeTierMoveIds[repertoire.side].add(move.id);
						});
					});
				}
				let queue: QuizGroup[] = [];
				const now = new Date().toISOString();
				let repertoires = options.repertoireId
					? [REPERTOIRE_STATE().getStandardAndSuperRepertoires()![options.repertoireId]]
					: Object.values(rs.superRepertoires!);
				repertoires = shuffle(repertoires);
				const reviewStyleSetting = () =>
					USER_STATE().getFrontendSetting("reviewStyle") as FrontendSettingOption<ReviewStyle>;
				forEach(repertoires, (repertoire) => {
					const side = repertoire.side;
					const seen_epds = new Set();
					const recurse = (epd: string, line: string[], pendingQueue: QuizGroup[]) => {
						let allResponses: RepertoireMove[] = [...(repertoire.positionResponses[epd] ?? [])];
						allResponses = sortBy(allResponses, (r) => {
							return 1 - Math.random() * (r.incidence ?? 0);
						});
						const responses: RepertoireMove[] = filter(
							repertoire.positionResponses[epd],
							(r) => !r.isDisabled,
						);
						const newPendingQueue = [...pendingQueue];
						if (responses?.[0]?.mine) {
							const needsToReviewAny = some(responses, (r) =>
								SpacedRepetitionStatus.isReviewDue(r.srs!, now),
							);
							const anyDifficult = some(responses, (r) =>
								SpacedRepetitionStatus.isDifficult(r.srs),
							);
							const shouldAdd =
								(options.filter === "difficult-due" && anyDifficult && needsToReviewAny) ||
								(options.filter === "recommended" && needsToReviewAny) ||
								(options.filter === "due" && needsToReviewAny) ||
								(options.filter === "difficult" && anyDifficult) ||
								options.filter === "all" ||
								options.filter === "early";
							if (shouldAdd || reviewStyleSetting().value === ReviewStyle.EntireLine) {
								const quizGroup: QuizGroup = {
									moves: responses.map((r) => {
										return {
											...r,
											learning: !onboarding && r.srs?.firstReview,
										};
									}),
									epd: epd,
									line: line,
									side,
								};
								if (shouldAdd) {
									if (!isEmpty(pendingQueue)) {
										quizGroup.precedingGroups = pendingQueue;
									}
									queue.push(quizGroup);
								}
								if (
									reviewStyleSetting().value === ReviewStyle.EntireLine &&
									!isEmpty(quizGroup.line)
								) {
									const withoutLearning: QuizGroup = {
										...quizGroup,
										synthetic: true,
										moves: map(quizGroup.moves, (m) => ({
											...m,
											learning: undefined,
										})),
									};
									newPendingQueue.push(withoutLearning);
								}
								if (options.includePlans) {
									responses.forEach((r) => {
										const plans = repertoire.plans[r.epdAfter];
										if (!plans) {
											return;
										}
										const fen = `${r.epdAfter} 0 1`;
										const position = new Chess(fen);
										const quizPlans = parsePlansToQuizMoves(plans, side, position);
										queue.push({
											plans: [...quizPlans],
											remainingPlans: quizPlans,
											completedPlans: [],
											line: [...line, r.sanPlus],
											side,
											epd,
										});
									});
								}
							}
						}

						map(allResponses, (m) => {
							if (!seen_epds.has(m.epdAfter)) {
								seen_epds.add(m.epdAfter);
								recurse(m.epdAfter, [...line, m.sanPlus], newPendingQueue);
							}
						});
					};
					recurse(options.startPosition ?? START_EPD, options.startLine ?? [], []);
				});
				if (options.filter === "recommended") {
					const byIncidence = sortBy(
						map(queue, (m) => Quiz.getMoves(m)?.[0].incidence ?? 0),
						(v) => -v,
					);
					const commonFudgeFactor = 4;
					let commonCutoff = byIncidence[COMMON_MOVES_CUTOFF] ?? last(byIncidence);
					// so that you go a bit deeper on the lines that appear first,
					commonCutoff = commonCutoff / commonFudgeFactor;
					const commonQueue = take(
						filter(queue, (m) => {
							const moves = Quiz.getMoves(m);
							if (!moves) {
								return false;
							}
							return (moves[0].incidence ?? 0) >= commonCutoff;
						}),
						COMMON_MOVES_CUTOFF,
					);
					const epds = map(commonQueue, (q) => q.epd);
					if (options.includePlans) {
						const commonWithPlans = filter(queue, (m) => epds.includes(m.epd));
						queue = commonWithPlans;
					} else {
						queue = commonQueue;
					}
				}
				if (options.filter === "early") {
					const dues = sortBy(map(queue, (m) => Quiz.getMoves(m)?.[0].srs?.dueAt));
					const earlyCutoff = dues[EARLY_MOVES_CUTOFF] ?? "0";
					const earlyQueue = take(
						filter(queue, (m) => {
							const moves = Quiz.getMoves(m);
							if (!moves?.[0].srs?.dueAt) {
								return false;
							}
							return moves[0].srs?.dueAt <= earlyCutoff;
						}),
						EARLY_MOVES_CUTOFF,
					);
					const epds = map(earlyQueue, (q) => q.epd);
					if (options.includePlans) {
						const commonWithPlans = filter(queue, (m) => epds.includes(m.epd));
						queue = commonWithPlans;
					} else {
						queue = earlyQueue;
					}
				}
				const countOfPlans = countBy(queue, (q: QuizGroup) => Quiz.isPlansQuiz(q)).true;
				const maxPlans = getMaxPlansForQuizzing();
				if (countOfPlans > maxPlans) {
					const ratio = maxPlans / countOfPlans;
					queue = filter(queue, (q) => {
						if (Quiz.isPlansQuiz(q)) {
							return Math.random() < ratio;
						}
						return true;
					});
				}
				queue.forEach((q) => {
					let moves = Quiz.getMoves(q);
					if (moves) {
						forEach(moves, (move) => {
							if (!USER_STATE().isSubscribed() && !freeTierMoveIds[q.side].has(move.id)) {
								q.blockedByFreeTier = true;
							}
						});
					}
				});
				return queue;
			}),
		buildQueueFromMistakes: (mistakes: LichessMistake[]) =>
			set(([_s, rs]) => {
				const queue: QuizGroup[] = [];
				forEach(mistakes, (m) => {
					const responses = rs.superRepertoires![m.side].positionResponses[m.epd];
					queue.push({
						moves: responses,
						lichessMistake: m,
						epd: m.epd,
						line: Line.fromPgn(m.line),
						side: m.side,
					});
				});
				return queue;
			}),
		inspectCurrentLine: () =>
			set(([s, rs]) => {
				const m = s.currentQuizGroup;
				if (!m) {
					return;
				}
				const multipleMoves = Quiz.getMoves(m)?.length ?? 0 > 1;
				const repertoireIds = new Set(
					Quiz.getMoves(m)?.flatMap((m) => m.repertoireIds || []) ?? [],
				);
				let repertoires: Repertoire[] = [...repertoireIds].map(
					(id: Uuid) => REPERTOIRE_STATE().repertoires![id]!,
				);
				if (isEmpty(repertoires) && s.activeOptions?.repertoireId && rs.repertoires) {
					repertoires = [rs.getStandardAndSuperRepertoires()[s.activeOptions.repertoireId]!];
				}
				if (isEmpty(repertoires)) {
					Sentry.captureException(new Error("No repertoire found for inspecting current line"), {
						extra: {
							options: JSON.stringify(s.activeOptions),
						},
					});
					return;
				}
				const viewInRepertoireBuilder = (repertoire) => {
					rs.backToOverview();
					UI().pushView(RepertoireBuilder);
					rs.browsingState.startBrowsing(repertoire.id, "build", {
						lineToPlay: m.line,
						animated: false,
					});
				};
				if (repertoires.length === 1) {
					viewInRepertoireBuilder(repertoires[0]);
				} else {
					UI().pushView(SidebarTemplate, {
						props: {
							header: "Select a repertoire",
							bodyPadding: true,
							children: (
								<p class="body-text">
									{multipleMoves ? "These moves are " : "This move is"} in multiple repertoires,
									which one do you want to view?
								</p>
							),
							actions: repertoires.map((r) => {
								return {
									onPress: () => {
										viewInRepertoireBuilder(r);
									},
									text: r.name,
									style: "primary",
								} as SidebarAction;
							}),
						},
					});
				}
			}),
		onFailDifficultMove: () =>
			set(([s]) => {
				s.playCorrectMove({ markAs: "incorrect" });
				s.failedDifficultMove = true;
			}),
		isCurentMoveVeryDifficult: () =>
			set(([_s]) => {
				// todo: bring this back?
				return false;
				// if (!s.currentQuizGroup) {
				// 	return;
				// }
				// const moves = Quiz.getMoves(s.currentQuizGroup);
				// if (moves) {
				// 	const move = moves[0];
				// 	if (isMoveVeryDifficult(move)) {
				// 		return true;
				// 	}
				// }
				// return false;
			}),
		invalidateSession: () =>
			set(([s, _rs]) => {
				if (UI().mode === "review") {
					s.onSessionEnd.add(() => {
						set(([s]) => {
							s.activeQueue = [];
						});
					});
				} else {
					s.activeQueue = [];
				}
			}),
		updateQueue: (options: ReviewOptions) =>
			set(([s]) => {
				s.activeQueue = s.buildQueue(options) ?? [];
				s.reviewStats = {
					due: countQueue(s.activeQueue),
					incorrect: 0,
					correct: 0,
				};
			}),
		reviewLine: (line: string[], repertoire: Repertoire) =>
			set(([s, rs]) => {
				const onboarding = rs.onboarding.isOnboarding;
				const side = repertoire.side;
				rs.backToOverview();
				const queue: QuizGroup[] = [];
				let epd = START_EPD;
				const lineSoFar: string[] = [];
				line.map((move) => {
					const responses = repertoire.positionResponses[epd];
					const response = find(repertoire.positionResponses[epd], (m) => m.sanPlus === move);
					if (!response) {
						return;
					}
					if (response.mine) {
						queue.push({
							moves: (responses ?? []).map((r) => ({
								...r,
								learning: !onboarding && r.srs?.firstReview,
							})),
							line: [...lineSoFar],
							epd,
							side,
						});
					} else {
					}
					epd = response.epdAfter;
					lineSoFar.push(move);
				});

				s.startReview({
					repertoireId: repertoire.id,
					customQueue: queue,
					cameFrom: "review_specific_line",
				});
			}, "reviewLine"),
		getNextReviewPositionMove: () =>
			get(([s]) => {
				return first(s.getRemainingReviewPositionMoves());
			}),
		getRemainingReviewPositionMoves: () =>
			get(([s]) => {
				return filter(Quiz.getMoves(s.currentQuizGroup!), (m) => {
					return isNil(s.completedReviewPositionMoves[m.sanPlus]);
				});
			}),
	};

	initialState.chessboard = createChessboardInterface()[1];
	initialState.chessboard.set((c) => {
		// c.frozen = true;
		c.delegate = {
			askForPromotionPiece: (requestedMove: Move) => {
				return get(([s]) => {
					const currentMove = s.currentQuizGroup ? Quiz.getMoves(s.currentQuizGroup!)?.[0] : null;
					if (!currentMove) {
						return null;
					}
					const moveObjects = s.chessboard
						.get((s) => s.position)
						.validateMoves([currentMove?.sanPlus]);
					if (!moveObjects) {
						return null;
					}
					const moveObject = moveObjects[0];
					if (requestedMove.promotion) {
						return moveObject.promotion ?? null;
					}
					return null;
				});
			},
			onPositionUpdated: () => {
				set(([s]) => {
					s.onPositionUpdate();
				});
			},
			madeMove: noop,
			tappedSquare: (square) =>
				set(([s]) => {
					const remaningPlans = Quiz.getRemainingPlans(s.currentQuizGroup!, s.planIndex);
					if (!remaningPlans) {
						return;
					}
					const plan = remaningPlans[0];
					const correct = includes(plan.toSquares, square);
					if (correct) {
						s.chessboard.setTapOptions([]);
					}
					s.chessboard.showMoveFeedback(
						{
							square,
							result: correct ? "correct" : "incorrect",
							size: correct ? "large" : "small",
						},
						() => {
							set(([s]) => {
								if (correct) {
									s.planIndex++;
									s.setupPlans();
								}
							});
						},
					);
				}),
			shouldMakeMove: (move: Move) =>
				set(([s]) => {
					const matchingResponse = find(
						Quiz.getMoves(s.currentQuizGroup!),
						(m) => move.san === m.sanPlus,
					);
					if (matchingResponse) {
						if (!matchingResponse.learning) {
							s.reviewStats.correct++;
						}
						s.completedReviewPositionMoves[matchingResponse.sanPlus] = matchingResponse;
						const willUndoBecauseMultiple = !isEmpty(s.getRemainingReviewPositionMoves());
						let shouldProgress = !willUndoBecauseMultiple;
						Quiz.getMoves(s.currentQuizGroup!)?.forEach((move) => {
							s.allReviewPositionMoves[move.epd][move.sanPlus].reviewed = true;
						});
						if (s.incorrectGuessesThisQuizGroup > 1 && s.quizGroupStage !== QuizGroupStage.Arrows) {
							shouldProgress = false;
							s.quizGroupStage = QuizGroupStage.Full;
						}
						s.chessboard.showMoveFeedback(
							{
								square: move.to as Square,
								result: "correct",
							},
							() => {
								set(([s]) => {
									if (willUndoBecauseMultiple) {
										s.chessboard.backOne({ clear: true });
									}
									if (shouldProgress) {
										s.setupNextQuizGroup();
									}
								});
							},
						);
						return true;
					}
					s.incorrectGuessesThisQuizGroup++;
					s.chessboard.showMoveFeedback(
						{
							square: move.to as Square,
							result: "incorrect",
						},
						() => {
							s.chessboard.backOne({ clear: true });
							if (s.isCurentMoveVeryDifficult()) {
								s.onFailDifficultMove();
							}
							s.markRemaining({ markAs: "incorrect", completed: false });
						},
					);
					return true;
				}),
		};
	});
	return initialState;
};
