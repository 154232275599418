import { isMobile } from "~/utils/isMobile";

const VERTICAL_SPACING = [
	{
		from: "header",
		to: "body-text",
		value: isMobile() ? 16 : 24,
	},
	{
		from: "tabs",
		to: "table",
		value: 48,
	},
	{
		from: "table",
		to: "table",
		value: 36,
	},
	{
		from: "collapsible-section",
		to: "table",
		value: 18,
	},
	{
		from: "table",
		to: "actions",
		value: 36,
	},
	{
		from: "table",
		to: "body-text",
		value: 48,
	},
	{
		from: "body-text",
		to: "body-text",
		value: 12,
	},
	{
		from: "body-text",
		to: "table",
		value: 36,
	},
	{
		from: "body-text",
		to: "form",
		value: 24,
	},
	{
		from: "body-text",
		to: "bullets",
		value: 12,
	},
	{
		from: "body-text",
		to: "actions",
		value: 32,
	},
	{
		from: "actions",
		to: "actions",
		value: 32,
	},
	{
		from: "header",
		to: "form",
		value: 12,
	},
	{
		from: "header",
		to: "table",
		value: isMobile() ? 16 : 24,
	},
	{
		from: "table",
		to: "table-header",
		value: 46,
	},
	{
		from: "actions",
		to: "table",
		value: 46,
	},
	{
		from: "actions",
		to: "bar",
		value: 36,
	},
] as const;

type SpacerComponentType =
	| (typeof VERTICAL_SPACING)[number]["from"]
	| (typeof VERTICAL_SPACING)[number]["to"];

type ValidSpacerPairs = (typeof VERTICAL_SPACING)[number] extends infer T
	? T extends { from: infer F; to: infer T }
		? [F, T]
		: never
	: never;

// @ts-ignore
const VERTICAL_SPACING_LOOKUP: Record<
	SpacerComponentType,
	Record<SpacerComponentType, number | ((isMobile: boolean) => number)>
> = {};

for (const s of VERTICAL_SPACING) {
	VERTICAL_SPACING_LOOKUP[s.from] = VERTICAL_SPACING_LOOKUP[s.from] || {};
	VERTICAL_SPACING_LOOKUP[s.from][s.to] = s.value;
}

export const Spacer = (props: {
	between?: ValidSpacerPairs;
	width?: number | null;
	height?: number | null;
	block?: boolean;
	grow?: boolean;
	style?: any;
}) => {
	const _styles = () => {
		const styles: any = { "flex-grow": props.grow ? 1 : 0, ...props.style };
		if (props.block) {
			styles.display = "block";
		}
		if (props.height) {
			styles.height = `${props.height}px`;
		}
		if (props.width) {
			styles.width = `${props.width}px`;
		}
		if (props.between) {
			const value = VERTICAL_SPACING_LOOKUP[props.between[0]]?.[props.between[1]];
			if (value === undefined) {
				console.warn("Uncovered between value", props.between);
			} else {
				const resolvedValue = typeof value === "function" ? value(isMobile()) : value;
				styles.height = `${resolvedValue}px`;
			}
		}
		return styles;
	};
	return <div style={_styles()} class="shrink-0" />;
};
