import { every, isEmpty } from "lodash-es";
import { createEffect, createMemo } from "solid-js";
import { Spacer } from "~/components/Space";
import { TableResponse } from "~/types/TableResponse";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, MODEL_GAMES_STATE, REPERTOIRE_STATE, quick } from "~/utils/app_state";
import { registerViewMode } from "~/utils/register_view_mode";
import { usePlansAction } from "~/utils/sharedActions";
import { onBack } from "~/utils/signals/onBack";
import { c, stylex } from "~/utils/styles";
import { shouldUsePeerRates } from "~/utils/table_scoring";
import { BP, useResponsiveV2 } from "~/utils/useResponsive";
import { SidebarHeader } from "./RepertoireEditingHeader";
import { RepertoireMovesTable } from "./RepertoireMovesTable";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";

export const PlansBrowser: EnrichedComponent<null> = function Responses() {
	const currentEpd = () => BROWSING_STATE().chessboard.getCurrentEpd();
	const activeSide = () => BROWSING_STATE().activeSide;
	const positionReport = () =>
		REPERTOIRE_STATE().positionReports[BROWSING_STATE().activeRepertoireId!]?.[currentEpd()];

	const currentSide = () => BROWSING_STATE().currentSide;
	const plansAction = usePlansAction();
	const tableResponses = () => BROWSING_STATE().tableResponses;

	const actions = createMemo(() => {
		let actions: SidebarAction[] = [];
		if (plansAction()) {
			actions.push({ ...plansAction()!, text: "View plans from here", style: "primary" });
			actions.push({
				text: "Play through a model game from here",
				style: "primary",
				onPress: () => {
					quick((_s) => {
						MODEL_GAMES_STATE().fetchAndReviewNewGame({
							epd: currentEpd(),
							line: BROWSING_STATE().chessboard.get((s) => s.moveLog),
							side: activeSide()!,
						});
					});
				},
			});
		}
		return actions;
	});

	const usePeerRates = () => shouldUsePeerRates(positionReport());
	const allLocalStockfish = () =>
		every(tableResponses(), (m) => TableResponse.getStockfishEvalSource(m) === "local") &&
		tableResponses().length > 0;
	const header = "What do you want to study?";
	const responsive = useResponsiveV2();

	const moveLog = () => BROWSING_STATE().chessboard.get((v) => v).moveLog;
	createEffect(() => {
		onBack(
			() => {
				quick((s) => {
					if (!isEmpty(moveLog())) {
						animateSidebar("left");
						s.repertoireState.browsingState.chessboard.backOne();
					} else {
						s.repertoireState.browsingState.reset();
					}
				});
			},
			isEmpty(moveLog()) ? "animate" : "stay",
		);
	});

	return (
		<div style={stylex(c.column, c.constrainWidth)}>
			<div class="padding-sidebar">
				<SidebarHeader>{header}</SidebarHeader>
			</div>
			<Spacer height={responsive().switch(20, [BP.md, 24])} />
			<div style={stylex()} id={`browse-select-${currentEpd()}`}>
				<RepertoireMovesTable
					{...{
						usePeerRates,
						allLocalStockfish: allLocalStockfish(),
						activeSide: activeSide()!,
						side: currentSide()!,
						responses: tableResponses,
					}}
				/>
			</div>
			<Spacer between={["table", "actions"]} />
			<SidebarActions actions={actions()} />
		</div>
	);
};

registerViewMode(PlansBrowser, "plans_and_model_games");
